import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryLog',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      startTime,
      endTime
    })
  })
}

export function QueryVisitorLog(pageIndex, pageSize, sortKey, sortDirect, filters, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/QueryVisitorLog',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      startTime,
      endTime
    })
  })
}

export function ExportVisitorLog(pageIndex, pageSize, sortKey, sortDirect, filters, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/ExportVisitorLog',
    method: 'post',
    responseType: 'blob',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      startTime,
      endTime
    })
  })
}